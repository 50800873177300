const oidcSettings = {
  authority: "https://www.pagosdigitales.com/identityserver",
  clientId: "appRSP",
  redirectUri: "https://rsp.pagosdigitales.com/oidc-callback/",
  responseType: "code",
  scope:
    "openid " +
    "profile " +
    "APD.Profile " +
    "Api.GobiernoChihuahua.Full " +
    "Api.Geografia " +
    "Api.SMS " +
    "Api.Bancos " +
    "Api.SistemaPagos " +
    "APD.RSP.Profile " +
    "apd.az.functions " +
    "offline_access",
  automaticSilentRenew: true,
  silentRedirectUri: "https://rsp.pagosdigitales.com/silent-renew-oidc.html",
};

export default oidcSettings;
