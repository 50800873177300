import { format } from "date-fns";

export const ToDate = (value: string | undefined): string => {
  if (value) {
    return format(Date.parse(value), "dd/MM/yyyy");
  } else {
    return "";
  }
};

export const ToDateTime = (value: string): string => {
  if (value) {
    if (typeof value == "string") {
      return format(Date.parse(value), "MM/dd/yy HH:mm:ss");
    } else {
      return format(value, "MM/dd/yy HH:mm:ss");
    }
  } else {
    return "";
  }
};

export const ToMoney = (value: number): string => {
  if (typeof value !== "number") {
    return value;
  }
  return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
