import Vue from "vue";
import Snackbar from "./store/modules/errorSnackbar";
import { mdiLogout } from "@mdi/js";

export default Vue.extend({
  name: "AppComponent",
  components: {
    AppFooter: () => import("@/components/appFooter/appFooter.vue"),
    AppMenu: () => import("@/components/appMenu/appMenu.vue"),
    AppToolbar: () => import("@/components/appToolbar/appToolbar.vue"),
    SpinnerComponent: () => import("@/components/spinner.vue"),
  },
  data() {
    return {
      LeftDrawer: false as boolean,
      RightDrawer: false as boolean,
      IconLogout: mdiLogout,
    };
  },
  computed: {
    IsAuthenticated(): boolean | undefined {
      return this.$store.getters["oidcStore/oidcIsAuthenticated"];
    },
    IsDevMode(): boolean {
      if (process.env.NODE_ENV === "production") {
        return false;
      } else {
        return true;
      }
    },
    snack: {
      get() {
        return Snackbar.snackbar;
      },
      set() {
        Snackbar.Hide();
      },
    },
    snackMsg(): string {
      return Snackbar.errorMsg;
    },
  },
  methods: {
    Logout(): void {
      this.$store.dispatch("oidcStore/signOutOidc");
    },
    HideSnack() {
      Snackbar.Hide();
    },
  },
});
