import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "../";

@Module({
  namespaced: true,
  name: "ErrorSnackbar",
  store,
  dynamic: true,
})
class ErrorSnackbarModule extends VuexModule {
  public errorMsg = "";
  public snackbar = false;

  @Action
  public Show(msg: string) {
    this.change({ value: true, msg: msg });
    return;
  }

  @Action
  public Hide() {
    this.change({ value: false, msg: "" });
    return;
  }

  @Mutation
  private change(data: { value: boolean; msg: string }) {
    this.snackbar = data.value;
    this.errorMsg = data.msg;
  }
}
export default getModule(ErrorSnackbarModule);
