import Vue from "vue";
import Vuex from "vuex";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import DevoidcSettings from "../plugins/oidcSettings.dev";
import ProdoidcSettings from "../plugins/oidcSettings.prod";
import { Rules } from "@/models/rules";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    packageVersion: process.env.VUE_APP_VERSION || "0",
    mode: process.env.NODE_ENV as string,
    validationRules: {
      email: (v: string) => (v || "").match(/@/) || "Email no tiene formato.",
      length: (v: string, len: number) => {
        return (v || "").length >= len || `Debe ingresar minimo ${len} caracteres.`;
      },
      password: (v: string) => {
        return (
          (v || "").match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          "Password debe contener una mayuscula, numero y caracter especial."
        );
      },
      required: (v: string) => !!v || "Dato obligatorio",
      notEmpty: (v: string[]) => (v && v.length > 0) || "Dato obligatorio",
    } as Rules,
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    appMode: (state) => {
      return state.mode;
    },
  },
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      process.env.NODE_ENV === "production" ? ProdoidcSettings : DevoidcSettings,
      {
        namespaced: true,
        routeBase: process.env.BASE_URL,
      },
      {
        // userLoaded: (user: any) => console.log('OIDC user is loaded:', user),
        // userUnloaded: () => console.log('OIDC user is unloaded'),
        // accessTokenExpiring: () => console.log('Access token will expire'),
        // accessTokenExpired: () => console.log('Access token did expire'),
        // silentRenewError: () => console.log('OIDC user is unloaded'),
        // userSignedOut: () => console.log('OIDC user is signed out'),
      },
    ),
  },
});
export default store;
