import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  { path: "/", component: () => import("@/views/index.vue") },
  {
    path: "/ReporteQr",
    component: () => import("@/views/reporteQr/reporteQr.vue"),
  },
  {
    path: "/ReporteCajeros",
    component: () => import("@/views/reporteCajeros/reporteCajeros.vue"),
  },
  {
    path: "/ReportePlc",
    component: () => import("@/views/reportePlc.vue"),
  },
  {
    path: "/ReportePagos",
    component: () => import("@/views/reportePagos/reportePagos.vue"),
  },
  {
    path: "/ReportePagosPersonalizados",
    component: () => import("@/views/reportePagosPersonalizado/reportePagosPersonalizado.vue"),
  },
  {
    path: "/ReporteGobChihuahua",
    component: () => import("@/views/reporteGobChihuahua/reporteGobChihuahua.vue"),
  },
  {
    path: "/ResumenPagos",
    component: () => import("@/views/resumenPagos/resumenPagos.vue"),
  },
  {
    path: "/ReporteCortes",
    component: () => import("@/views/cortesCajeros/corteCajeros.vue"),
  },
  {
    path: "/ReporteVisitas",
    component: () => import("@/views/visitas.vue"),
  },
  {
    path: "/DetalleCortes",
    component: () => import("@/views/detalleCortes.vue"),
  },
  {
    path: "/CorteTC",
    component: () => import("@/views/corteTC.vue"),
  },
  {
    path: "/ReporteToneleros",
    component: () => import("@/views/reporteToneleros/reporteTonelero.vue"),
  },
  {
    path: "/Reporte",
    component: () => import("@/components/reporte.vue"),
  },
  {
    path: "/CambiosNoDispensados",
    component: () => import("@/views/reporteCND_DND/reporteCND.vue"),
  },
  {
    path: "/CND",
    component: () => import("@/views/reporteCnd.vue"),
  },
  {
    path: "/pagarCnd",
    component: () => import("@/views/pagarCnd.vue"),
  },
  {
    path: "/oidc-callback",
    component: () => import("@/views/callback.vue"),
    meta: { isOidcCallback: true },
  },
  {
    path: "/Denegado",
    component: () => import("@/views/denegado.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/Error",
    component: () => import("@/views/callbackError.vue"),
    meta: { isPublic: true },
  },
  {
    path: "*",
    component: () => import("@/views/notFound.vue"),
    meta: { isPublic: true },
  },
];

export default routes;
